@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.about-container {
    margin-top: 70px
}

.about-container h1 {
    font-family: 'DM Sans', sans-serif;
    font-size: 64px;
    font-weight: 700;
    text-align: center;
}

.about-container h3 {
    font-family: 'DM Sans', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-left: 30%;
    margin-top: -1%;
}

.about-container-rect {
    background: transparent;
    border-radius: 20px;
    border: 5px solid #F79F2D;
    width: 25dvw;
    height: 80px;
    position: absolute;
    top: 11%;
    right: 29%;
    z-index: -1;
}

.about-container-our-story {
    padding: 20px;

}

.about-container-our-story h2 {
    text-align: center;
    font-size: 50px;
    font-weight: 400;
}

.about-container-our-story h5 {
    font-family: 'DM Sans', sans-serif;
    font-size: 24px;
    font-weight: 400;
    width: 80%;
    text-align: center;
    margin: auto;
}

.about-container-our-story button {
    padding: 14px 18px;
    border-radius: 30px;
    background: linear-gradient(0deg, #F79F2D 0%, #F79F2D 100%), linear-gradient(180deg, rgba(0, 101, 255, 0.00) 0%, #2684FF 100%), #4C9AFF;
    border: none;
    stroke: none;
    color: #fff;
    width: max-content;
    margin-top: 2%;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    margin-left: 45%;
}

.date-class {
    color: #000;
    font-size: 16px;
    font-weight: bold;
}

.about-container-our-journey h2 {
    text-align: center;
    font-size: 50px;
    font-weight: 400;
}

.spacer {
    width: 90%;
    margin: auto;
    text-align: center;
    background-color: #F79F2D;
    height: 8px;
    border-radius: 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

 h2 {
    text-align: center;
    font-size: 50px;
    font-weight: 400;
}

.team-container {
    display: flex;
    justify-content: space-evenly;
  }
  
  .team-member-card {
    text-align: center;
    margin: 20px;
  }
  
  .team-member-image {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease; 
  }

  .team-member-image:hover {
    filter: grayscale(80%); 
    cursor: pointer;
}
  
  .team-member-name {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  .team-member-position {
    font-size: 18px;
    font-weight: 200;
    text-align: center;
    width: 200px;
  }

  .mentor-about {
    width: 300px;
    padding: 10px;
  }

  .team-member-card button {
    background: transparent;
    border: none;
    stroke: none;
    color: #7F8390;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
  }

  .about-container-pink-clouds {
    z-index: -2;
    position: absolute;
    top: 50%;
    left: 25%;
  }

  .about-container-pink-clouds2 {
    z-index: -2;
    position: absolute;
    left: 30%;
  }

  .popup-about {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-about img {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .popup-about-inner {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 40dvw;
    text-align: center;
    position: relative;
  }

  .popup-about-header {
    display: flex;

  }

  .popup-about-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-left: 1rem;
  }

  .popup-about-title h4 {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  .popup-about-title h6 {
    font-size: 1rem;

  }
  .popup-about-inner p {
    text-align: start;
  }

  .popup-about-role {
    margin: 0;
    padding: 0;
    margin-top: 0.5rem;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: 1px solid black; 
    font-size: 1.5rem;
    cursor: pointer;
    border-radius: 50%; 
    color: grey;
    padding: 8px 15px 8px 15px;
    transition: background-color 0.3s ease;
}

.close-btn:hover {
  background-color: lightgray; 
  color: none;
}
  
  .popup-about-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    .about-container {
      margin:0;
      padding: 0;
    }

    .about-container h1 {
      font-size: 50px;
    }

    .about-container-rect {
      background: transparent;
      border-radius: 20px;
      border: 5px solid #F79F2D;
      width: 50vw;
      height: 25vh;
      position: absolute;
      top: 11%;
      opacity: 0.5;
      right: 25%;
      z-index: -1;
    }

    .about-container-our-story {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .team-container {
      display: flex;
      flex-direction: column;
    }

    .mentor-about {
      width: 100%;
    }

    .about-container h1 {
      font-size: 1.5rem;
      margin-top: 10rem;
    }
    .about-container-rect {
      display: none;
    }

    .about-container-our-story h2 {
      font-size: 1.5rem;
    }

    .about-container-our-story h5 {
      font-size: 1rem;
    }

    .about-container-our-story button {
      margin-left: 0;
    }

    .about-container h2 {
      font-size: 1.5rem;
    }

    .team-container {
      align-items: center;
    }

    .team-member-name{
      font-size: 1rem;
    }

    .team-member-position{
      font-size: 1rem;
    }
    .team-member-card button {
      font-size: 1rem;
    }

    .about-container-pink-clouds,
    .about-container-pink-clouds2 {
      display: none;
    }
    .popup-about-inner {
      width: 90dvw;
      margin: 1rem;
    }  
    
  }