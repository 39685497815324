@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

* {
  overflow-x: hidden;
}

.home-parent-container {
  width: 100%;
}

.home-main-container {
  width: 100%;
  height: 100vh;
}

.home-main-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.home-why-is-it-important-container {
  width: 100%;
  padding: 20px;
}

.home-main-center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%);*/
  text-align: center; 
  color: white;
  animation: slideIn 1s ease forwards;
}


.home-main-center-text button {
  border-radius: 40px;
  background: linear-gradient(0deg, #F79F2D 0%, #F79F2D 100%);
  border: none;
  stroke: none;
  display: flex;
  width: auto;
  color: white;
  font-size: 15px;
  padding: 20px 30px;
  align-items: center;
  justify-content: center;
  margin: auto;
  cursor: pointer;
}

.home-main-center-text h1 {
  margin-top: 0;
  font-size: 80px;
}

.home-main-center-text h2 {
  font-weight: 400;
  font-size: 30px;
  margin-top: -20px;
}

.popup {
  position: fixed;
  width: 50%;
  height: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: red;
}

.home-what-do-we-do-container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  background: linear-gradient(to right, #F79F2D 0%, #F79F2D 40%, #3375CF 40%, #3375CF 100%);
  animation: slideIn2 1s ease forwards;
}

.home-what-do-we-do-container-2 {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
  position: relative;
}

.home-what-do-we-do-container-2-mission::before {
  content: '';
  background-image: url('../../assets/image/vision.png');
  background-size: cover;
  position: absolute;
  background-position: center top -150px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-what-do-we-do-container-2-mission::after {
  content: '';
  background-color: rgba(236, 154, 0, 0.4); 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} 

.home-what-do-we-do-container-2-vision::before {
  content: '';
  background-image: url('../../assets/image/IMG_1300.JPG');
  background-position: center top -150px;
  background-size:cover;
  background-repeat:no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.home-what-do-we-do-container-2-vision::after {
  content: '';
  background-color: rgba(22, 127, 247, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} 

.home-what-do-we-do-container-2-vision,
.home-what-do-we-do-container-2-mission {
  color: white;
  position: relative; 
  animation: slideIn2 1s ease forwards;
  width: 90%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}

.home-what-do-we-do-container-2-vision {
  align-items: start;
  border-radius: 2rem 2rem 0 0;
}

.home-what-do-we-do-container-2-vision h2 {
  /* text-align: start;
  background-color: #3375CF;
  width: min-content;
  padding-left: 10px;
  padding-right: 40px;
  border-radius: 0 40px 40px 0 ;*/
  background-color: #3375CF;
  width: min-content;
  padding: 0 2rem 0 2rem;
  
}

.home-what-do-we-do-container-2-vision span {
  /* text-align: start;
  background-color: #3375CF; */
  font-size: xx-large;
  background-color: #3375CF;
  padding: 2rem;
}

.home-what-do-we-do-container-2-mission span {
  /* text-align: start;
  background-color: #F79F2D;
  padding: 10px; */
  font-size: xx-large;
  background-color: #F79F2D;
  padding: 2rem;
}

.home-what-do-we-do-container-2-mission {
  display: flex;
  flex-direction: column;
  align-items: end;
  border-radius: 0 0 2rem 2rem;
}

.home-what-do-we-do-container-2-mission h2 {
  /* text-align: end;
  background-color: #F79F2D;
  padding-left: 40px;
  padding-right: 10px;
  width: min-content;
  border-radius: 40px 0 0 40px ; */
  background-color: #F79F2D;
  width: min-content;
  padding: 0 2rem 0 2rem;
}

.home-what-do-we-do-container-2-vision h2 ,
.home-what-do-we-do-container-2-mission h2{
  position: relative;
  z-index: 1;
}

.home-what-do-we-do-container-2-vision span,
.home-what-do-we-do-container-2-mission span{
  position: relative;
  z-index: 1;
}

.home-what-do-we-do-container-operation h2 {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.home-what-do-we-do-container-operation {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.operation-text {
  position: relative;
  text-decoration: none;
  overflow: hidden;
}

.operation-text::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px; 
  width: 100%;
  height: 10px; 
  background-color: orange;
  z-index: -1;
}

.home-what-do-we-do-container-operation-mobilize
{
  display: flex;
  flex-direction: column;
  width: min-content;
  align-items: center;
  padding: 1rem;
}

.circle-number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #297EEB; /* Blue color for the circle */
  color: white;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.home-what-do-we-do-container-operation-arrow,
.home-what-do-we-do-container-operation-arrow-inverted {
  background-image: url('../../assets/image/arrow.png');
  background-size:auto;
  background-repeat: no-repeat;
  width: 35%;
  height: 100px;
}

.home-map-container h2 {
  overflow: hidden;
}

.home-what-do-we-do-container-operation-arrow-inverted {
  transform: rotateY(180deg);
}

.home-what-do-we-do-container-operation-mobilize-left {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.home-what-do-we-do-container-operation-mobilize-right {
  display: flex;
  justify-content: end;
  align-items: center;
}

.home-what-do-we-do-container-operation-mobilize-invisible {
  visibility: hidden;
}

.home-what-do-we-do-container-operation-mobilize-outer {
  display: flex;
  justify-content: start;
  align-items: center;
}

.read-more {
  padding: 14px 18px;
    border-radius: 30px;
    background: linear-gradient(0deg, #F79F2D 0%, #F79F2D 100%), linear-gradient(180deg, rgba(0, 101, 255, 0.00) 0%, #2684FF 100%), #4C9AFF;
    border: none;
    stroke: none;
    color: #fff;
    width: max-content;
    margin-top: 2%;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
    margin-left: 45%;
}

.home-what-do-we-do-container h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 400;
}

.home-what-do-we-do-container h4 {
  font-family: 'DM Sans', sans-serif;
  font-size: 32px;
  font-weight: 400;
  width: 65%;
  text-align: center;
  margin: auto;
}

.home-what-do-we-do_sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quarter {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quarter:hover {
  background-color: #f0f0f0;
}

.quarter.expanded {
  background-color: #e0e0e0;
  font-size: 1.5rem;
}

.home-what-do-we-do_sub-container_info {
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 50%;
}

.home-what-do-we-do_sub-container_info span:first-child {
  font-family: 'DM Sans', sans-serif;
  font-size: 32px;
  font-weight: 500;
}

.home-what-do-we-do_sub-container_info span:nth-child(2) {
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin-top: 15px;
}

.home-what-do-we-do_sub-container_info button {
  
  padding: 14px 18px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 30px;
  background: linear-gradient(0deg, #F79F2D 0%, #F79F2D 100%), linear-gradient(180deg, rgba(0, 101, 255, 0.00) 0%, #2684FF 100%), #4C9AFF;
  border: none;
  stroke: none;
  color: #fff;
  width: max-content;
  margin-top: 10%;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
}

.home-container-pink-clouds {
  position: absolute;
  z-index: -2;
  margin-top: 15%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
}

.home-why-is-it-important-container h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 400;
  margin-top: 2%;
}

.home-why-is-it-important-container_image {
  width: 80vw;
  height: 600px;
  object-fit: contain;
}

.home-why-is-it-important-container__container-image {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 2rem;
  padding: 10px;
}

.home-why-is-it-important-container__container-mission-vision {
  display: flex;
  justify-content: space-evenly;
}

.home-why-is-it-important-container__container-mission-vision div {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-why-is-it-important-container__container-mission-vision h3 {
  width: 50%;
  font-family: 'DM Sans', sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.important-rectangle-line {
  min-width: 10px;
  /* Set your desired minimum width here */
  height: 30vh;
  position: absolute;
  background-color: #F79F2D;
  margin: 0;
  padding: 0;
  right: 5%;
  transform: translate(-100%, -110%);
}

.important-rectangle-line-2 {
  min-width: 10px;
  /* Set your desired minimum width here */
  height: 30vh;
  position: absolute;
  background-color: #F79F2D;
  margin: 0;
  padding: 0;
  right: 5%;
  transform: translate(-1500%, -62%) rotate(90deg);
}

.home-map-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-our-partners-container {
  margin-top: 5rem;
}

.home-our-partners-container-outer{
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding: 1rem;
  margin-top: -5%;
}

.home-our-partners-sep {
  width: 1px;
  background-color: #F79F2D;
}

.home-our-partners-container-p {
  font-size: x-large;
  font-weight: 200;
}

.home-our-partners-container-img {
  max-width: 100%; 
  max-height: 100%; 
  width: auto;
  height: auto; 
}

.home-our-partners-container-box {
  margin: 1rem;
}

.home-our-partners-container h1 {
  margin: auto;
  text-align: center;
  margin-bottom: 8%;
  font-size: 50px;
  font-weight: 400;
}

.home-facts-outer {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  padding: 2%;
  width: 100%;
  background-color: #F79F2D;
  margin-top: 1rem;
}

.home-facts-card {
  border-radius: 10px;
  padding: 2%;
  background-color: #1353a7;
}

.home-map-container img{
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width:35%;
}

.home-facts-title-span {
  font-size: x-large;
  font-weight: 400;
  color: #fff;
}

.home-facts-count-span {
  font-size: x-large;
  font-weight: 500;
  color: #ffc508;
}

.popup-first {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  padding: 1rem;
}

.popup-first {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup-first img {
  margin: 0;
  padding: 0;
}

.story-button {
  background-color: orange;
  color: white;
  border: none;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 1rem;
  cursor: pointer;
}

.story-button:hover {
  opacity: 0.8;
}

.later-button {
  background: none;
  color: gray;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
}

.later-button:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .home-what-do-we-do_sub-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }
  
  .home-what-do-we-do_sub-container_info {
    width: auto;
    padding: 10px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home-why-is-it-important-container{
    padding: 0;
  }
  .home-why-is-it-important-container__container-mission-vision {
    flex-direction: column;
  }
  .home-why-is-it-important-container__container-mission-vision h3 {
    width: auto;
  }
  .important-rectangle-line {
    visibility:collapse;
  }

  .important-rectangle-line-2 {
    visibility:collapse;
  }
  .home-partners-container {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  img {
    max-width: 100%;
    height: auto;
    padding: 10px;
  }
  .home-why-is-it-important-container__container-image {
    flex-direction: column;
  }
  .home-facts-outer {
    flex-direction: column;
    margin-top: 1rem;
  }
  .home-our-partners-container-outer {
    flex-direction: column;
  }

  .home-main-center-text h1 {
    font-size: 3rem;
  }

  .home-main-center-text h2 {
    font-size: 1.25rem;
  }

  .home-main-center-text button {
    font-size: 0.75rem;
    padding: 6px 12px;
  }

  .popup {
    width: 90%;
    height: 50%;
  }

  .home-what-do-we-do-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text within the container */
    margin-top: 1rem;
    margin-left: 0;
    padding-left: 0;
    background: linear-gradient(to bottom, #a8793b 0%, #806138 40%, #3375CF 40%, #3375CF 100%);
    animation: slideIn2 1s ease forwards;
  }

  .home-what-do-we-do-container h2 {
    font-size: small;
  }

  .home-what-do-we-do-container-2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
    position: relative;
    padding: 0;
    margin-left: 0;
  }

  .home-what-do-we-do-container-2-vision::before {
    background-image: url('../../assets/image/IMG_1300.JPG');
    background-position: center top ;
  }

  .home-what-do-we-do-container-2-vision h2 {
    font-size: 2rem;
  }
  
  .home-what-do-we-do-container-2-vision span {
    font-size: 1rem;
  }

  .home-what-do-we-do-container-2-mission::before {
    background-image: url('../../assets/image/vision.png');
    background-position: center top ;
  }

  .home-what-do-we-do-container-2-mission h2 {
    font-size: 2rem;
  }
  
  .home-what-do-we-do-container-2-mission span {
    font-size: 1rem;
  }

  .operation-text {
    font-size: 2rem;
  }

  .home-what-do-we-do-container-operation h2 {
    font-size: 2rem;
  }

  .circle-number {
    font-size: 1rem;
  }

  .home-what-do-we-do-container-operation-mobilize-invisible {
    display:none;
  }
  .home-what-do-we-do-container-operation-mobilize h2 {
    font-size: 1.5rem;
  }
  .home-what-do-we-do-container-operation-mobilize-outer img {
    width: 5rem;
  }
  .home-what-do-we-do-container-operation-mobilize p {
    font: 1rem;
  }

  .read-more {
    margin-left: 0;
  }
  .home-what-do-we-do-container-operation-arrow,
  .home-what-do-we-do-container-operation-arrow-inverted {
    background-size: contain; 
    background-position: center;
  }

  .home-facts-card {
    font-size: 1.5rem;
    width: 85%;
    margin: 1rem;
  }

  .home-facts-count-span {
    font-size: 1.5rem;
  }

  .home-map-container h2{
    font-size: 1.5rem;
  }

  .home-map-container span{
    font-size: 1.5rem;
  }
  .home-map-container img{
    height: 20rem;
    width: 100%;
  }
  .home-our-partners-container h1{
    font-size: 1.5rem;
  }

  .popup-first { 
    width: 85%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20%) translateX(-50%);
    opacity: 0;
  }
  to {
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
  }
}


@keyframes slideIn2 {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
