.blog-container {
    padding: 2rem;
    margin-top: 60px;
}

.blog-container h1 {
    text-align: center;
}

.blog-container a {
    display: flex;
    overflow: hidden;
    justify-content: space-evenly;
    align-items: center;
    padding: 1rem;
    border-radius: 20px;
    text-decoration: none;
    width: 80%;
    margin: auto;
}

.blog-container h2 {
        width: 50%;
       color: #000; 
       text-align: left;
       font-size: 40px;
       margin-left: 2rem;
}

.blog-container h2:hover {
    color: #808080;
}

.blog-container img {
    width: 30vw;
    height: 30vh;
    object-fit: cover;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) { 
    
    .blog-container {
        margin: 0;
        padding: 0;
    }

    .blog-container h1 {
        margin-top: 10rem;
    }

    .blog-container a {
        flex-direction: column;
        margin: 0;
        padding: 10px;
        margin-top: 10px;
    }

    .blog-container h2 {
        width: 100%;
       color: #000; 
       text-align: center;
       font-size: 1.2rem;
       margin-left: 0;
    }

    .blog-container img {
        width: 100%;
        height: 40%;
        object-fit: cover;
        border: none;
        border-radius: 20px;
    }
}