.carousel-container {
  width: 100%;
}

.carousel-slide a {
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
  margin: 2rem;
  padding: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  text-decoration: none;
}

.carousel-slide h4 {
  width: 50%;
 color: #000; 
 text-align: left;
 margin-left: 2rem;
}

.carousel-slide h2:hover {
  color: #808080;
}


.carousel-slide img {
  width: 20vw;
  height: 10vh;
  object-fit: cover;
  border-radius: 20px;
}

.carousel-slide {
  padding-top: 2rem;
  overflow: hidden;
}

.active-slide a {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
  margin: 2rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  text-decoration: none;
}

.active-slide  h4 {
  width: 50%;
 color: #000; 
 text-align: left;
 margin-left: 2rem;
}

.active-slide h2:hover {
  color: #808080;
}


.active-slide img {
  width: 30vw;
  height: 20vh;
  object-fit: cover;
  border-radius: 20px;
}

.slick-dots {
  bottom: 20px; 
}

.slick-dots li button:before {
  font-size: 10px; 
  color: #297EEB; 
}

.slick-dots li.slick-active button:before {
  color: #FFA500; 
}

/* .carousel-slide a {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-evenly;
  align-items: center;
  margin: 1rem; 
  padding: 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  text-decoration: none;
}

.carousel-slide h4 {
  width: 90%; 
  color: #000; 
  text-align: center; 
  margin: 0.5rem 0;
}

.carousel-slide img {
  width: 90%; 
  height: auto; 
  object-fit: cover;
  border-radius: 20px;
}

.carousel-slide {
  padding-top: 1rem; 
}

.active-slide img {
  width: 100%; 
  height: auto; 
} */

.slick-slider {
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.slick-list {
  overflow: visible;
}

@media (max-width: 768px) {
  .carousel-slide a {
    margin: 0.5rem; /* Adjusted spacing for smaller screens */
    padding: 0.5rem; /* Adjusted padding for smaller screens */
  }

  .carousel-slide h4 {
    font-size: 1rem; /* Smaller font size for mobile */
  }

  .carousel-slide img {
    width: 100%; /* Full width for mobile */
  }
}