nav {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: rgba(131, 131, 131, 0.7);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
}

.right {
  display: flex;
  align-items: center;
}

.right a {
  color: #F79F2D;
  font-size: x-large;
  text-decoration: none;
  margin: 0 15px;
  font-weight: 500;
  -webkit-text-stroke: 0.1px #774100;
  transition: color 0.3s ease, font-weight 0.3s ease;
}

.right a:hover {
  font-weight: 700;
  color: #0f73ff;
}

.menu-icon {
  display: none;
  font-size: 40px;
  cursor: pointer;
}

.popup-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-menu-content {
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 80%;
  max-width: 400px;
  backdrop-filter: blur(10px); /* Apply a blur effect */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Optional: Add a subtle shadow */
}

.popup-menu-content a {
  display: block;
  margin-bottom: 10px;
  color: black;
  font-size: 20px;
  text-decoration: none;
  margin-top: 1rem;
  transition: font-weight 0.3s ease;
}

.popup-menu-content a:hover {
  font-weight: 700;
}

.close-icon {
  font-size: 30px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .right {
    display: none;
  }

  .menu-icon {
    display: block;
    margin-right: 1.5rem;
  }

  .close-icon {
    position: relative;
    right: 0;
    margin-bottom: 2rem;
  }
}