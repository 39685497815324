@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
.footer-container {
    height: 40vh;
    background: linear-gradient(0deg, #F79F2D 0%, #F79F2D 100%);
    margin-top: 10%;
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    color: #fff;
}

.footer-container-row {
    display: flex;
    align-items: center;
    margin-top: -8%;
    font-size: 1.5rem;
    cursor: pointer;
}

.footer-container h6 {
    margin-left: 5%;
    
    width: 60%;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.footer-container h3 {
    font-family: 'DM Sans', sans-serif;
    font-size: 25px;
    font-weight: 500;
}

.footer-container-quick {
    display: flex;
    flex-direction: column;
}

.footer-container-quick h6 {
    margin-top: -12%;
    cursor: pointer;
}

.footer-container-follow {
    display: flex;
    justify-content: space-evenly;

}

.footer-container-follow a {
    background-color: #fff;
    color: #F79F2D;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;height: 50px; 
    font-size: 1.5rem;
    cursor: pointer;
    
}

.footer-container-follow a:not(:first-child) {
    margin-left: 10px;
}

.footer-container-link {
    color: #fff;
    font-family: 'DM Sans', sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
    text-decoration: none;
}

@media (max-width: 768px) {
    .footer-container {
        display: flex;
        flex-direction:column ;
        height: auto;
    }
    .footer-container-row {
      margin: -20;
      padding: 0;
    }
}
